<template>
  <v-dialog v-model="shower" scrollable persistent max-width="500px">
    <v-card>
      <v-card-text style="height: 370px" class>
        <div class="ma-6 pa-0 text-center">
          <img
            src="@/assets/img/trash.png"
            width="140px"
            height="140px"
            alt="One Box"
          />
        </div>
        <div v-if="type == 'inbox' || type == 'outbox'" class="text-center">
          <h2>{{ $t("text_want_delete") }}</h2>
        </div>
        <div v-else class="text-center">
          <h2>{{ $t("text_want_delete_permanently") }}</h2>
        </div>
        <div class="text-center mb-5">
          <br />
          <v-chip v-if="checkdelete === false">
            <span style="font-size: 20px;">
              {{ $t("Number_documents") }} {{ total_file }} {{ $t("items") }}
              <!-- เช็คประเภทการลบ มาจากหน้าไหน -->
              <!-- {{type}} -->
            </span>
            <!-- <span style="font-size: 20px;" v-else>
              {{ $t("Number_documents") }} {{ this.total_file }} {{ $t("items") }}
              ไม่มีเอกสารที่ฟิลเตอร์
            </span> -->
          </v-chip>
          <v-chip v-else>
            <span style="font-size: 20px;">{{ $t("mutideletedocinboxoutbox.deletedoc") }} : {{listdata}} / {{totalfile}}</span>
            </v-chip>
        </div>
        <br />
        <div class="text-center">
          <!-- <v-btn color="red" outlined @click="$emit('closedialog')">{{
            $t("changeName.close")
          }}</v-btn> -->
          <v-btn
            class="mr-5"
            color="red"
            outlined
            @click="$emit('closedialog')"
            >{{ $t("checkdelete.cancel") }}</v-btn
          >
          <v-btn 
            v-if="type == 'inbox' || type == 'outbox'"
            color="#C51D1D"
            dark
            @click="type_()"
            :loading="loaddataprogress"
            >{{ $t("checkdelete.delete") }}</v-btn
          >
          <v-btn
            v-else
            color="#C51D1D"
            dark
            @click="type_()"
            :loading="loaddataprogress"
            >{{$t('trashRClick.forcedelete')}}</v-btn
          >
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  data() {
    return {
      file_data: [],
      dialog: false,
      loaddataprogress: false,
      total_file:0,
      listdata: 0,
      checkdelete: false,
    };
  },
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "color",
      "role_level",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if (this.show === true) {
          this.checkdelete = false;
            this.listdata = 0;
          this.total_file = this.totalfile;
          console.log("data", this.data);
          console.log("status", this.status);
        }
        return this.show;
      },
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
  },
  props: ["show", "data", "status", "totalfile", "filedata","type"],
  methods: {
    type_(){
      console.log("TYPEEEEEEE");
      if(this.type == 'inbox'){
        console.log("inbox");
        this.delete_filter_inbox()
      }else if (this.type == 'outbox'){
        console.log("outbox");
        this.delete_filter_outbox()
      }else if (this.type == 'trashinbox'){
        console.log("trashinbox");
        this.delete_filter_trash_inbox()
      }else if (this.type == 'trashoutbox'){
        console.log("trashoutbox");
        this.delete_filter_trash_outbox()
      }
      // this.type_delete = this.type
      // if(this.type_delete == "inbox"){
      //   console.log("inbox :",this.type_delete);
      // }else{
      //   console.log("outbox :",this.type_delete);
      // }
    },
    async delete_filter_inbox() {
      // this.file_data.push(this.filedata);
      console.log("deleteeeeeeeeee");
      console.log("filedata", this.filedata);
      this.total_file = this.totalfile
      console.log("totalfile",this.totalfile);
      if(this.total_file == 0){
        Toast.fire({
            icon: "error",
            title: this.$t('dialogconfirmdelete.undocumented_delete'),
        });
      }else{
        this.checkdelete = true;
      for (let i = 0; i < this.filedata.data.length; i++) {

        let payload = {
          inbox_id: [this.filedata.data[i]["inbox_id"]],
          account_id: this.dataAccountId,
        };
        console.log("payload", payload);
      
      // console.log("showdelete", this.showdelete);
       let auth = await gbfGenerate.generateToken();
       await this.axios
         .post(
           process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/delete_inbox",
           payload,
          { headers: { Authorization: auth.code } }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            this.loaddataprogress = true;
            this.listdata = i+1;
            // Toast.fire({
            //   icon: "success",
            //   title: "OK",
            // });
            console.log("delete", response.length, response.data);
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
          if(i== this.filedata.data.length -1){
            this.loaddataprogress = false,
            this.$emit('closedialog')
            this.$emit('reload')

             Toast.fire({
               icon: "success",
               title: this.$t('dialogconfirmdelete.deletesuccess'),
             });
          }
        });
      }
      }
    },
    async delete_filter_outbox() {
      // this.file_data.push(this.filedata);
      console.log("deleteeeeeeeeee");
      console.log("filedata", this.filedata);
      this.total_file = this.totalfile
      console.log("totalfile",this.totalfile);
      if(this.total_file == 0){
        Toast.fire({
            icon: "error",
            title: this.$t('dialogconfirmdelete.undocumented_delete'),
        });
      }else{
        this.checkdelete = true;
      for (let i = 0; i < this.filedata.data.length; i++) {

        let payload = {
          outbox_id: [this.filedata.data[i]["inbox_id"]],
          account_id: this.dataAccountId,
        };
        console.log("payload", payload);
      
      // console.log("showdelete", this.showdelete);
       let auth = await gbfGenerate.generateToken();
       await this.axios
         .post(
           process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/delete_outbox",
           payload,
          { headers: { Authorization: auth.code } }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            this.loaddataprogress = true;
            this.listdata = i+1;
            // Toast.fire({
            //   icon: "success",
            //   title: "OK",
            // });
            console.log("delete", response.length, response.data);
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
          if(i== this.filedata.data.length -1){
            this.loaddataprogress = false,
            this.$emit('closedialog')
            this.$emit('reload')

             Toast.fire({
               icon: "success",
               title: this.$t('dialogconfirmdelete.deletesuccess'),
             });
          }
        });
      }
      }
    },
    async delete_filter_trash_inbox(){
      console.log("delete_filter_trash_inbox");
      console.log("filedata",this.filedata);
      if(this.total_file == 0){
        Toast.fire({
            icon: "error",
            title: this.$t('dialogconfirmdelete.undocumented_permanently'),
        });
      }else{
        this.checkdelete = true;
      for (let i = 0; i < this.filedata.length; i++) {
        console.log("inbox_id",this.filedata[i]["inbox_id"]);
      
        let payload = {
          business_id: this.dataAccountActive.business_info.business_id,
          data_type: "inbox",
          inbox_id: [this.filedata[i]["inbox_id"]],
          
          // account_id: this.dataAccountId,
        };
        console.log("payload", payload);
      
       let auth = await gbfGenerate.generateToken();
       await this.axios
         .post(
           process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/v1/delete_file_inbox_outbox",
           payload,
          { headers: { Authorization: auth.code } }
        )
        .then((response) => {
          if (response.data.status === "OK") {
              console.log("delete forever");
            this.loaddataprogress = true;
            this.listdata = i+1;
            console.log("delete forever", response.length, response.data);
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
          if(i== this.filedata.length -1){
            this.loaddataprogress = false,
            this.$emit('closedialog')
            this.$emit('reload')

             Toast.fire({
               icon: "success",
               title: this.$t('dialogconfirmdelete.permanentlydelete_success'),
             });
          }
        });
      }
      }
    },
    async delete_filter_trash_outbox(){
      console.log("delete_filter_trash_outbox");
      console.log("filedata",this.filedata);
      if(this.total_file == 0){
        Toast.fire({
            icon: "error",
            title: this.$t('dialogconfirmdelete.undocumented_permanently'),
        });
      }else{
        this.checkdelete = true;
      for (let i = 0; i < this.filedata.length; i++) {
        console.log("inbox_id",this.filedata[i]["inbox_id"]);
      
        let payload = {
          business_id: this.dataAccountActive.business_info.business_id,
          data_type: "outbox",
          inbox_id: [this.filedata[i]["inbox_id"]],
          
          // account_id: this.dataAccountId,
        };
        console.log("payload", payload);
      
       let auth = await gbfGenerate.generateToken();
       await this.axios
         .post(
           process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/v1/delete_file_inbox_outbox",
           payload,
          { headers: { Authorization: auth.code } }
        )
        .then((response) => {
          if (response.data.status === "OK") {
              console.log("delete forever");
            this.loaddataprogress = true;
            this.listdata = i+1;
            console.log("delete forever", response.length, response.data);
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
          if(i== this.filedata.length -1){
            this.loaddataprogress = false,
            this.$emit('closedialog')
            this.$emit('reload')

             Toast.fire({
               icon: "success",
               title: this.$t('dialogconfirmdelete.permanentlydelete_success'),
             });
          }
        });
      }
      }
    }
  },
};
</script>
<style></style>
